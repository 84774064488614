import { getTheme, styled } from '~/utils';

const inMobile = getTheme('inMobile');

export const Content = styled.section`
  flex: 1;
  width: 100%;
  background: rgb(230, 239, 248);

  background-color: red;

  @media ${inMobile} {
    background-color: red;
  }
`;
