import { ScenePublic } from '~/components';
import { getTheme, ifStyle, pxToRem, styled } from '~/utils';

type TextFieldsProps = {
  roxy?: boolean;
};

const sceneSpacing = getTheme('sceneSpacing');
const smallSpacing = getTheme('smallSpacing');
const secondaryMain = getTheme('secondary.main');
const primaryMain = getTheme('primary.main');
const modalRadius = getTheme('modalRadius');
const inMobile = getTheme('inMobile');
const inTablet = getTheme('inTablet');
const primaryDark = getTheme('primary.dark');

const isRoxy = ifStyle('roxy');

export const Container = styled(ScenePublic)`
  width: 100%;
  padding: ${sceneSpacing};
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media ${inMobile} {
    flex-direction: column;
    padding: ${smallSpacing};
  }

  @media ${inTablet} {
    padding: 0.6rem;
  }
`;

export const Body = styled.section`
  width: 85%;
  min-height: 95vh;
  max-height: 95vh;
  background-color: ${secondaryMain};
  border-radius: ${modalRadius};
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  padding: ${smallSpacing};
  margin-left: ${pxToRem(20)};

  @media ${inMobile} {
    margin: 0px;
    width: 100%;
  }

  overflow-y: auto;
`;

export const Header = styled.section`
  justify-content: space-between;
  align-items: center;
  padding-top: 11px;
  margin-bottom: ${pxToRem(30)};

  @media ${inMobile} {
    flex-direction: column;
  }
`;

export const Title = styled.h1`
  font-weight: 400;
  color: ${primaryDark};
  display: flex;
  align-items: center;
  font-size: 1.2rem;

  @media ${inMobile} {
    font-size: 1rem;
  }
`;

export const Text = styled.h1`
  font-weight: 400;
  color: ${primaryDark};
  display: flex;
  align-items: center;
  font-size: 1rem;

  text-align: center;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${pxToRem(20)};
`;

export const SectionAdded = styled.div`
  min-width: ${pxToRem(150)};
  max-width: ${pxToRem(150)};
  word-wrap: break-word;
  overflow-wrap: break-word;

  display: flex;
  align-items: center;
  justify-content: center;

  @media ${inMobile} {
    min-width: 100%;
    max-width: 100%;
  }
`;

export const TextAdded = styled.h1<TextFieldsProps>`
  font-weight: 400;
  color: ${isRoxy(primaryMain, primaryDark)};
  cursor: ${isRoxy('pointer', 'default')};
  display: flex;
  font-size: 1rem;
  padding: 1px;
  text-align: center;

  @media ${inMobile} {
    padding: ${pxToRem(5)};
  }
`;

export const SectionBorder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: ${pxToRem(10)};
  border: 1px solid ${primaryDark}20;
  padding: ${pxToRem(10)};
  margin-bottom: ${pxToRem(20)};

  @media ${inMobile} {
    flex-direction: column;
  }
`;
