import { getTheme, ifStyle, pxToRem, styled } from '~/utils';

const secondaryMain = getTheme('secondary.main');
const modalRadius = getTheme('modalRadius');
const smallSpacing = getTheme('smallSpacing');
const secondaryContrast = getTheme('secondary.contrast');
const primaryMain = getTheme('primary.main');
const primaryLight = getTheme('primary.light');
const minimumSpacing = getTheme('minimumSpacing');
const inMobile = getTheme('inMobile');
const inTablet = getTheme('inTablet');

const isSelected = ifStyle('selected');

export const Content = styled.section`
  width: 15%;
  height: 95vh;
  background-color: ${secondaryMain};
  border-radius: ${modalRadius};
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  align-items: center;
  padding: ${minimumSpacing};

  @media ${inMobile} {
    height: auto;
    width: 100%;
    margin-bottom: ${smallSpacing};
  }

  @media ${inTablet} {
    height: 98vh;
    width: 30%;
    margin-bottom: 0;
  }
`;

export const WrapperUser = styled.section`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${minimumSpacing};
  max-height: ${pxToRem(70)};
`;

export const Img = styled.img`
  width: ${pxToRem(50)};
  height: ${pxToRem(50)};
  object-fit: contain;
`;

export const UserName = styled.h4`
  color: ${secondaryContrast};
  font-weight: 500;
  font-size: 0.9rem;
`;

export const Options = styled.section`
  width: 98%;
  height: 100%;
  flex-direction: column;

  border-top: 1px solid ${secondaryContrast}30;

  @media ${inMobile} {
    width: 90vw;
  }
`;

type Props = {
  selected?: boolean;
  disableHover?: boolean;
};

export const Item = styled.section<Props>`
  width: 98%;
  height: ${pxToRem(55)};
  flex-direction: row;
  align-items: center;
  padding: ${minimumSpacing};
  background-color: ${isSelected(primaryMain, 'transparent')};
  justify-content: space-between;
  margin: ${minimumSpacing} 0px;
  cursor: pointer;
  border-radius: ${pxToRem(5)};

  @media ${inMobile} {
    width: 100%;
  }
`;

export const ItemName = styled.h4<Props>`
  max-width: ${pxToRem(150)};
  font-weight: 400;
  color: ${isSelected(primaryLight, secondaryContrast)};
  font-size: ${pxToRem(14)};
  margin-left: ${minimumSpacing};
  text-align: left;
`;

export const RequestStatusText = styled.h4`
  color: ${secondaryContrast};
  font-weight: 400;
  width: 100%;
  font-size: 0.9rem;
  margin-left: ${pxToRem(10)};
`;

export const RequestClickedText = styled.h4`
  color: ${secondaryContrast};
  font-weight: 400;
  width: 100%;
  font-size: 0.9rem;
  margin: ${pxToRem(10)} 0px;
  color: ${primaryMain};
  cursor: pointer;
  margin-left: ${pxToRem(10)};
`;
