import React, { FC } from 'react';
import { Formik } from 'formik';
import moment from 'moment';
import { toast } from 'react-toastify';
import { observer, useStores, yup } from '~/utils';
import ManagerRequests from './ManagerRequests';

const validate = yup.object().shape({
  amount: yup.string().required('O campo é obrigatório.'),
  from: yup.string().required('O campo é obrigatório.'),
  to: yup.string().required('O campo é obrigatório.'),
});

const ManagerRequestsContainer: FC = () => {
  const { requests, app } = useStores();

  const onSubmit = async ({ amount, from, to }: Requests.ManagerRequessts) => {
    const now = moment().startOf('day');

    if (amount > requests.data?.totalTokens!) {
      toast.error('Quantidade é maior do que a disponível.');
      return;
    }

    if (moment(from).isBefore(now)) {
      toast.error('A data inicial é inferior á data atual');
      return;
    }

    if (moment(to).isBefore(now)) {
      toast.error('Data final é inferior á data atual.');
      return;
    }

    if (moment(from).isAfter(to)) {
      toast.error('Data inicial maior que data final.');
      return;
    }
    requests.onManagerRequests({ amount, from, to });

    app.onSetModal(null);
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{ amount: '', from: '', to: '' }}
      validationSchema={validate}
      enableReinitialize
    >
      <ManagerRequests />
    </Formik>
  );
};

export default observer(ManagerRequestsContainer);
