import { action, makeObservable, observable } from 'mobx';

export default class AppStore {
  @observable
  modal = '';

  constructor() {
    makeObservable(this);
  }

  @action
  onSetModal = async (data: App.Modal): Promise<void> => {
    this.modal = data || '';
  };
}
