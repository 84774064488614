import { Request } from '~/services';

export const login = async (
  credentials: User.LoginFBParams,
): Promise<User.LoginResponse | null> => {
  try {
    const { data } = await Request.post('/user/oauth', credentials);
    return data;
  } catch (error: any) {
    return null;
  }
};

export const create = async (
  credentials: User.CreateParamsAPI,
): Promise<Omit<User.CreateParams, 'pwd'> | null> => {
  try {
    const { data } = await Request.post('/user/signup', credentials);
    return data;
  } catch (error) {
    return null;
  }
};
