export const Path = {
  HOME: '/',
  Dash: '/dashboard',
  Solutions: '/solutions',
  FunctionalModels: '/functional-models',
  TechniqueModels: '/technique-models',
  Request: '/requests',
  LOGIN: '/login',
  PRIVATE: '/private',
  PUBLIC: '/public',
};
