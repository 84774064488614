import React, { FC } from 'react';
import colors from '~/theme/colors';

type Props = {};

const Icon: FC<Props> = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="30" height="30" rx="5" fill={colors.primary.main} />
    <path
      d="M23.7775 22.7033L20.786 19.7127C22.1631 18.0593 22.8499 15.9386 22.7033 13.7918C22.5567 11.645 21.5881 9.63735 19.999 8.18649C18.4099 6.73563 16.3226 5.95327 14.1714 6.00216C12.0201 6.05105 9.97054 6.92743 8.44899 8.44899C6.92743 9.97054 6.05105 12.0201 6.00216 14.1714C5.95327 16.3226 6.73563 18.4099 8.18649 19.999C9.63735 21.5881 11.645 22.5567 13.7918 22.7033C15.9386 22.8499 18.0593 22.1631 19.7127 20.786L22.7033 23.7775C22.7738 23.8481 22.8576 23.904 22.9497 23.9422C23.0419 23.9804 23.1407 24 23.2404 24C23.3402 24 23.4389 23.9804 23.5311 23.9422C23.6232 23.904 23.707 23.8481 23.7775 23.7775C23.8481 23.707 23.904 23.6232 23.9422 23.5311C23.9804 23.4389 24 23.3402 24 23.2404C24 23.1407 23.9804 23.0419 23.9422 22.9497C23.904 22.8576 23.8481 22.7738 23.7775 22.7033ZM7.53932 14.3719C7.53932 13.0205 7.94005 11.6995 8.69082 10.5759C9.44159 9.45231 10.5087 8.57656 11.7572 8.05942C13.0057 7.54228 14.3795 7.40697 15.7049 7.67061C17.0302 7.93424 18.2477 8.58498 19.2032 9.54053C20.1588 10.4961 20.8095 11.7135 21.0732 13.0389C21.3368 14.3643 21.2015 15.7381 20.6844 16.9866C20.1672 18.2351 19.2915 19.3022 18.1679 20.053C17.0442 20.8037 15.7232 21.2045 14.3719 21.2045C12.5604 21.2024 10.8237 20.4819 9.54275 19.201C8.26184 17.9201 7.54133 16.1834 7.53932 14.3719Z"
      fill="#fff"
    />
  </svg>
);

export default Icon;
