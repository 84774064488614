import { getTheme, ifStyle, pxToRem, styled } from '~/utils';

const tertiaryLight = getTheme('tertiary.light');
const primaryLight = getTheme('primary.light');
const modalRadius = getTheme('modalRadius');
const smallSpacing = getTheme('smallSpacing');
const primaryContrast = getTheme('primary.contrast');
const warning = getTheme('warning');
const minimumSpacing = getTheme('minimumSpacing');
const isProcessing = ifStyle('processing');
const success = getTheme('success');

const inMobile = getTheme('inMobile');
const inTablet = getTheme('inTablet');

export const Content = styled.section`
  cursor: pointer;
  width: 100%;
  min-height: ${pxToRem(85)};
  background-color: ${primaryLight};
  border: 0.5px solid ${tertiaryLight};
  border-radius: ${modalRadius};
  margin-top: ${smallSpacing};
  align-items: center;
  padding: ${smallSpacing};
  justify-content: space-between;

  @media ${inMobile} {
    padding: ${minimumSpacing};
    flex-direction: column;
  }

  @media ${inTablet} {
    flex-direction: column;
  }
`;

type Props = {
  processing?: boolean;
};

export const TextProcessing = styled.h6<Props>`
  font-size: ${pxToRem(16)};
  color: ${isProcessing(primaryContrast, primaryLight)};
  font-weight: 400;
  min-width: ${pxToRem(150)};
  background-color: ${isProcessing(warning, success)};
  text-align: center;
  border-radius: 5px;

  @media ${inMobile} {
    min-width: ${pxToRem(100)};
  }
`;

export const SectionDescription = styled.div`
  display: flex;
  width: 80%;
  justify-content: space-between;
  padding: ${minimumSpacing};
  align-items: center;

  @media ${inMobile} {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media ${inTablet} {
    width: 100%;
  }
`;

export const TextID = styled.h6`
  width: ${pxToRem(150)};
  text-align: center;
  font-size: ${pxToRem(16)};
  color: ${primaryContrast};
  font-weight: 600;
  border-right: 1px solid ${tertiaryLight};
`;

export const Name = styled.h6`
  width: ${pxToRem(150)};
  text-align: center;
  font-size: ${pxToRem(16)};
  color: ${primaryContrast};
  font-weight: 400;
  width: 100%;

  @media ${inMobile} {
    width: 90%;
  }
`;

export const Description = styled.h6`
  width: 70%;
  font-size: ${pxToRem(16)};
  color: ${primaryContrast};
  font-weight: 400;

  @media ${inMobile} {
    width: 90%;
  }
`;
