import Spacings from './spacings';

const breakpoints = {
  xs: '320px',
  sm: '480px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  xxl: '1920px',
  desktop: '1280px',
  tablet: '1024px',
};

export default {
  inMobile: `only screen and (min-width: ${breakpoints.xs}) and (max-width: ${breakpoints.sm})`,
  inLargeMobile: `only screen and (min-width: ${breakpoints.sm}) and (max-width: ${breakpoints.md})`,
  inTablet: `only screen and (min-width: ${breakpoints.md}) and (max-width: ${breakpoints.lg})`,
  inLargeTablet: `only screen and (min-width: ${breakpoints.lg}) and (max-width: ${breakpoints.xl})`,
  inDesktop: `only screen and (min-width: ${breakpoints.xl}) and (max-width: ${breakpoints.xxl})`,
  inMobileAndTablet: `only screen and (max-width: ${breakpoints.desktop})`,
  inSmallMobile: `only screen and (min-width: ${breakpoints.xs}) and (max-width: ${breakpoints.md})`,
  inExtraSmallMobile: `only screen and (max-width: ${breakpoints.xs})`,
} as TypeDevice;

function toInt(value: string): number {
  // eslint-disable-next-line radix
  return parseInt(value.split('px')[0]);
}

export const device = {
  isMobile: toInt(breakpoints.tablet) >= Spacings.screenWidth,
  isTablet:
    toInt(breakpoints.tablet) > Spacings.screenWidth &&
    toInt(breakpoints.desktop) < Spacings.screenWidth,
  isDesktop: toInt(breakpoints.desktop) >= Spacings.screenWidth,
} as DeviceType;
