import React, { FC, useState } from 'react';
import { observer } from 'mobx-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { MODAL, toOnlyNumbers, useFormikContext, useStores } from '~/utils';
import If from '../If';
import {
  Bold,
  Button,
  Fields,
  Paper,
  Paragraph,
  TextFields,
  Title,
} from './styles';

const UpdateRequests: FC = () => {
  const { app } = useStores();

  const { submitForm, values, resetForm, isSubmitting, setFieldValue } =
    useFormikContext<Requests.FormUpdateAmount>();

  return (
    <Modal
      open={app.modal === MODAL.UPDATE_REQUEST}
      onClose={() => app.onSetModal(null)}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Fade in={app.modal === MODAL.UPDATE_REQUEST} timeout={500}>
        <Paper>
          <Title>Atualizar Requisições</Title>
          <Paragraph>
            Neste formulário você pode adicionar ou remover requisições, basta
            inserir o novo valor desejado.
          </Paragraph>

          <Fields>
            <TextFields
              name="amount"
              value={values.amount}
              placeholder="0"
              onChange={(event) => {
                setFieldValue('amount', toOnlyNumbers(event.target.value));
              }}
            />
          </Fields>

          <Button
            onClick={(e) => {
              e.preventDefault();
              submitForm();

              setTimeout(() => {
                resetForm();
              }, 2000);
            }}
            type="submit"
          >
            <If condition={isSubmitting}>
              <CircularProgress size={25} style={{ color: 'white' }} />
            </If>
            <If condition={!isSubmitting}>Finalizar</If>
          </Button>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default observer(UpdateRequests);
