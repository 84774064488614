import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Icon } from '~/assets/img';
import { Button, Login, RecoveryPassword, Register } from '~/components';
import { Firebase } from '~/services';
import { useStores } from '~/utils';
import {
  Body,
  Container,
  Header,
  Img,
  Paragraph,
  Phrase,
  Title,
} from './styles';

const firebase = new Firebase();

const Home: FC = (): JSX.Element => {
  const { app } = useStores();

  const onModalLogin = () => app.onSetModal('LOGIN');

  useEffect(() => {
    // firebase.onAuthStateChanged();
  }, []);
  return (
    <>
      <Header>
        <Img src={Icon} />
        <Button onPress={onModalLogin}>Iniciar um Projeto</Button>
      </Header>

      <Container>
        <Body>
          <Title>EVY</Title>
          <Phrase>
            De desafios à soluções: Conecte-se com a IA que transforma ideias
            humanas em inovação em IA
          </Phrase>
          <Paragraph>
            Otimize a definição de soluções de IA em sua empresa com a EvyAI,
            integrando estratégia de negócios e use cases de IA com as
            capacidades e tecnologias de IA apropriadas.
          </Paragraph>

          <Button onPress={onModalLogin}>Iniciar um Projeto</Button>
        </Body>
      </Container>

      <Login />
      <Register />
      <RecoveryPassword />
    </>
  );
};

export default observer(Home);
