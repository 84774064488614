import { RouterStore } from 'mobx-react-router';
import AppStore from './app.store';
import FunctionalModels from './functional-models';
import IAStore from './ia.store';
import RequestsStore from './requests.store';
import TechniqueModels from './technique-models';
import UserStore from './user.store';

class RootStore {
  user: UserStore;

  app: AppStore;

  ia: IAStore;

  functionalModels: FunctionalModels;

  techniqueModels: TechniqueModels;

  requests: RequestsStore;

  constructor() {
    this.user = new UserStore(this);
    this.app = new AppStore();
    this.ia = new IAStore(this);
    this.functionalModels = new FunctionalModels(this);
    this.techniqueModels = new TechniqueModels(this);
    this.requests = new RequestsStore(this);
  }
}

const store = new RootStore();

export {
  RouterStore,
  UserStore,
  AppStore,
  IAStore,
  FunctionalModels,
  TechniqueModels,
  RequestsStore,
};

export default store;
