import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { MDXEditor, headingsPlugin } from '@mdxeditor/editor';
import { Menu } from '~/components';
import { Body, Container } from './styles';

const helloMarkdown = `Hello <u>world am **here**</u> more <u>under</u> line. Some \`code with backticks\` and <code>code tag</code> `;

const Dashboard: FC = (): JSX.Element => (
  <Container>
    <Menu selected={0} />
    <Body>
      <MDXEditor autoFocus markdown={helloMarkdown} onChange={() => {}} />{' '}
    </Body>
  </Container>
);

export default observer(Dashboard);
