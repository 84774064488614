import { ScenePublic } from '~/components';
import { getTheme, styled } from '~/utils';

const sceneSpacing = getTheme('sceneSpacing');
const smallSpacing = getTheme('smallSpacing');
const inMobile = getTheme('inMobile');
const secondaryMain = getTheme('secondary.main');
const modalRadius = getTheme('modalRadius');

export const Container = styled(ScenePublic)`
  width: 100%;
  padding: ${sceneSpacing};
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media ${inMobile} {
    padding: ${smallSpacing};
  }
`;

export const Body = styled.section`
  width: 85%;
  height: 100%;
  background-color: ${secondaryMain};
  border-radius: ${modalRadius};
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
`;
