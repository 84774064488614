import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { Back } from '~/assets/svg';
import { device } from '~/theme/breakpoints';
import Button from '../Button';
import If from '../If';
import {
  ButtonText,
  ContentForm,
  Header,
  Line,
  TextFields,
  Title,
  WrapperButtons,
} from './styles';

type Props = {
  onBack: () => void;
  enableBack?: boolean;
  onDownloadPDF: () => void;
  onDownloadWord: () => void;
};

const TechniqueModelsForm: FC<Props> = ({
  onBack,
  enableBack = false,
  onDownloadPDF,
  onDownloadWord,
}) => {
  const { submitForm, values, handleChange, isSubmitting, errors, touched } =
    useFormikContext<TechniqueModels.Params>();

  return (
    <ContentForm>
      <Header>
        <If condition={enableBack}>
          <Title>
            <Back onPress={onBack} />
            Análise Técnica de Modelos de IA
          </Title>
        </If>

        <If condition={!enableBack}>
          <Title>Análise Técnica de Modelos de IA</Title>
        </If>

        <WrapperButtons>
          <If condition={enableBack}>
            <ButtonText onClick={onDownloadPDF}>Baixar em PDF</ButtonText>

            <ButtonText onClick={onDownloadWord}>Baixar em Word</ButtonText>
          </If>

          <If condition={!device.isMobile}>
            <Button onPress={submitForm}>
              <If condition={isSubmitting}>
                <CircularProgress size={25} style={{ color: 'white' }} />
              </If>
              <If condition={!isSubmitting}> Gerar Solução</If>
            </Button>
          </If>
        </WrapperButtons>
      </Header>

      <Line>
        <TextFields
          fullWidth
          name="solutions"
          value={values.solutions}
          onChange={handleChange('solutions')}
          placeholder="Solução Orientada por IA Recomendada"
          error={touched.solutions && !!errors.solutions}
        />
      </Line>

      <Line>
        <TextFields
          fullWidth
          name="models"
          value={values.models}
          onChange={handleChange('models')}
          placeholder="Modelos Propostos"
          error={touched.models && !!errors.models}
        />
      </Line>

      <br />
      <If condition={device.isMobile}>
        <Button onPress={submitForm}>
          <If condition={isSubmitting}>
            <CircularProgress size={25} style={{ color: 'white' }} />
          </If>
          <If condition={!isSubmitting}> Gerar Solução</If>
        </Button>
      </If>
    </ContentForm>
  );
};

export default TechniqueModelsForm;
