import React, { FC } from 'react';
import colors from '~/theme/colors';

type Props = {
  onClick?: () => void;
};

const Icon: FC<Props> = ({ onClick = () => {} }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    fill="#21213C"
    viewBox="0 0 256 256"
    onClick={onClick}
    style={{ cursor: 'pointer' }}
  >
    <path d="M224,128a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128ZM40,72H216a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16ZM216,184H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z" />
  </svg>
);

export default Icon;
