import React, { FC } from 'react';
import { Formik, useStores, yup } from '~/utils';
import RecoveryPassword from './RecoveryPassword';

const validate = yup.object().shape({
  email: yup.string().email().required('O campo de email é obrigatório.'),
});

const RecoveryPasswordContainer: FC = () => {
  const { user } = useStores();

  const onSubmit = async ({ email }: User.RecoveryPwd) => {
    await user.onRecoveryPassword(email);
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{ email: '', pwd: '' }}
      validationSchema={validate}
    >
      <RecoveryPassword />
    </Formik>
  );
};

export default RecoveryPasswordContainer;
