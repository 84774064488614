import React, { FC } from 'react';

type Props = {
  onPress?: () => void;
};

const Icon: FC<Props> = ({ onPress }) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onPress}
    style={{ cursor: 'pointer' }}
  >
    <path
      d="M28.3843 20.8844L15.8843 33.3844C15.7682 33.5005 15.6303 33.5926 15.4786 33.6555C15.3268 33.7183 15.1642 33.7507 15 33.7507C14.8357 33.7507 14.6731 33.7183 14.5213 33.6555C14.3696 33.5926 14.2317 33.5005 14.1156 33.3844C13.9994 33.2682 13.9073 33.1304 13.8445 32.9786C13.7816 32.8269 13.7493 32.6642 13.7493 32.5C13.7493 32.3358 13.7816 32.1731 13.8445 32.0214C13.9073 31.8696 13.9994 31.7318 14.1156 31.6156L25.7328 20L14.1156 8.38438C13.881 8.14982 13.7493 7.83171 13.7493 7.5C13.7493 7.1683 13.881 6.85018 14.1156 6.61563C14.3501 6.38107 14.6683 6.24931 15 6.24931C15.3317 6.24931 15.6498 6.38107 15.8843 6.61563L28.3843 19.1156C28.5006 19.2317 28.5928 19.3696 28.6557 19.5213C28.7186 19.6731 28.7509 19.8357 28.7509 20C28.7509 20.1643 28.7186 20.3269 28.6557 20.4787C28.5928 20.6304 28.5006 20.7683 28.3843 20.8844Z"
      fill="#3C3940"
    />
  </svg>
);

export default Icon;
