import { action, makeObservable, observable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { Firebase } from '~/services';
import { UserAPI } from '~/services/api';
import { myIP } from '~/utils';
import Alert from '~/utils/helpers/toast';
import Root from './index';

const firebase = new Firebase();

export default class UserStore {
  root: typeof Root;

  @observable
  data: User.Data = {
    name: '',
    email: '',
    type: '',
  };

  constructor(root: typeof Root) {
    this.root = root;
    makeObservable(this);

    makePersistable(this, {
      name: 'User',
      properties: ['data'],
      storage: window.localStorage,
    });
  }

  @action
  login = async ({ email, pwd }: User.LoginParams): Promise<boolean> => {
    try {
      const tk = await firebase.onSignIn({ email, pwd });
      if (!tk) throw new Error('Erro ');

      const IP = await myIP();
      const data = await UserAPI.login({ email, tk, IP });

      if (!data) throw new Error('Erro ');

      runInAction(() => {
        this.data = {
          name: data.name,
          type: data.type,
          email: data.email,
        };
      });

      return true;
    } catch (error) {
      firebase.signOut();

      Alert({
        message:
          'Erro ao fazer login, revise suas informações e tente novamente.',
      });

      return false;
    }
  };

  @action
  create = async (credentials: User.CreateParams): Promise<void> => {
    const idTk = await firebase.onCreateUser(credentials);

    if (!idTk) {
      Alert({
        message:
          'Erro ao criar usuário, revise suas informações e tente novamente.',
      });
    } else {
      Alert({
        message: `Usuário criado com sucesso, enviamos um email de verificação para ${credentials.email}.`,
        type: 'success',
      });

      UserAPI.create({
        email: credentials.email,
        name: credentials.name,
        idTk,
      });
      this.root.app.onSetModal(null);
    }
  };

  @action
  onRecoveryPassword = async (email: string): Promise<void> => {
    const sended = await firebase.onResetPassword(email);

    if (!sended) {
      Alert({
        message:
          'Erro ao enviar email de recuperação, revise suas informações e tente novamente.',
      });

      return;
    }

    Alert({
      message: 'Email de recuperação enviado com sucesso.',
      type: 'success',
    });

    this.root.app.onSetModal(null);
  };
}
