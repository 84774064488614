import { getTheme, pxToRem, styled } from '~/utils';

const primaryLight = getTheme('primary.light');

const mediumRadius = getTheme('mediumRadius');
const smallSpacing = getTheme('smallSpacing');
const primaryMain = getTheme('primary.main');
const secondaryContrast = getTheme('secondary.contrast');
const smallRadius = getTheme('smallRadius');
const mediumSpacing = getTheme('mediumSpacing');
const primaryContrast = getTheme('primary.contrast');
const failure = getTheme('failure');
const minimumSpacing = getTheme('minimumSpacing');

export const Paper = styled.form`
  width: ${pxToRem(400)};
  min-height: ${pxToRem(20)};
  background-color: ${primaryLight};
  border-radius: ${mediumRadius};
  padding: ${smallSpacing};
  margin: 0 auto;
  align-self: center;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  font-family: 'Roboto', sans-serif;
  font-size: ${pxToRem(24)};
  color: ${primaryContrast};
`;

export const Paragraph = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: ${pxToRem(16)};
  font-weight: 300;
  margin-top: ${smallSpacing};
  display: flex;
  align-items: center;
`;

export const Bold = styled.b`
  font-family: 'Roboto', sans-serif;
  font-size: ${pxToRem(16)};
  color: ${primaryMain};

  &:hover {
    cursor: pointer;
    color: ${primaryMain}50;
    transition: 0.3s;
  }
`;

export const Fields = styled.section`
  display: flex;
  flex-direction: column;
`;

export const TextFields = styled.input`
  border: 0.4px solid ${secondaryContrast}50;
  border-radius: ${smallRadius};
  height: ${pxToRem(60)};
  font-size: ${pxToRem(16)};
  margin-top: ${smallSpacing};
  padding: ${smallSpacing};
`;

export const Button = styled.button`
  border: 0;
  height: ${pxToRem(50)};
  background-color: ${primaryMain};
  border-radius: ${smallRadius};
  color: ${primaryLight};
  font-size: ${pxToRem(18)};
  cursor: pointer;
  margin-top: ${mediumSpacing};
`;

export const TextError = styled.p`
  text-align: center;
  color: ${failure};
  margin-top: ${minimumSpacing};
`;
