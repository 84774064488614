import { ScenePublic } from '~/components';
import { getTheme, pxToRem, styled } from '~/utils';

const sceneSpacing = getTheme('sceneSpacing');
const smallSpacing = getTheme('smallSpacing');
const secondaryMain = getTheme('secondary.main');
const modalRadius = getTheme('modalRadius');
const inMobile = getTheme('inMobile');
const inTablet = getTheme('inTablet');

export const Container = styled(ScenePublic)`
  width: 100%;
  padding: ${sceneSpacing};
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media ${inMobile} {
    flex-direction: column;
    padding: ${smallSpacing};
  }

  @media ${inTablet} {
    padding: 0.6rem;
  }
`;

export const Body = styled.section`
  width: 85%;
  min-height: 95vh;
  background-color: ${secondaryMain};
  border-radius: ${modalRadius};
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  padding: ${smallSpacing};
  margin-left: ${pxToRem(20)};

  @media ${inMobile} {
    margin: 0px;
    width: 100%;
  }
`;

export const CardSolutionsList = styled.section`
  padding-top: ${pxToRem(25)};
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;
