import { Request } from '~/services';

export const onFetchStatusTokens =
  async (): Promise<Requests.StatusUser | null> => {
    try {
      const { data } = await Request.get('/tokens/user/status');

      return data;
    } catch (error: any) {
      return null;
    }
  };

export const onManagerRequests = async ({
  amount,
  from,
  to,
}: Requests.ManagerRequessts): Promise<Requests.StatusUser | null> => {
  try {
    const { data } = await Request.post('/tokens/user/reserve', {
      startDate: from,
      endDate: to,
      reservedTokens: amount,
    });

    return data;
  } catch (error: any) {
    return null;
  }
};

export const onSetTokens = async ({
  amount,
  userID,
}: Requests.SetAmountTokens): Promise<Requests.All[]> => {
  try {
    const { data } = await Request.post('/tokens/user/amount', {
      amount,
      userID,
    });

    return data;
  } catch (error: any) {
    return [];
  }
};

export const allUsers = async (): Promise<Requests.All[]> => {
  try {
    const { data } = await Request.get('/tokens/user/all');

    return data;
  } catch (error: any) {
    return [];
  }
};
