import React, { FC, useEffect, useRef, useState } from 'react';
import FileSaver from 'file-saver';
import { Formik, FormikHelpers } from 'formik';
import { observer } from 'mobx-react';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import {
  MDXEditor,
  headingsPlugin,
  listsPlugin,
  markdownShortcutPlugin,
  thematicBreakPlugin,
} from '@mdxeditor/editor';
import { CardDataIA, If, Menu, TechniqueModelsForm } from '~/components';
import { IAAPI } from '~/services/api';
import { clearMarkdown, debounce, useStores, yup } from '~/utils';
import { Body, CardSolutionsList, Container } from './styles';
import '@mdxeditor/editor/style.css';

const TechniqueModels: FC = (): JSX.Element => {
  const refPrint = useRef<any>();
  const ref = useRef<any>();
  const [edit, setEdit] = useState(false);
  const [selected, setSelected] = useState<Partial<TechniqueModels.Response>>(
    {},
  );

  const { techniqueModels, requests, ia } = useStores();

  const onSubmit = async (
    data: TechniqueModels.Params,
    { setSubmitting }: FormikHelpers<TechniqueModels.Params>,
  ) => {
    try {
      await techniqueModels.onGenerate(data);

      setSubmitting(false);
    } catch (error) {
      // ..
    } finally {
      requests.onFetchStatusTokens();
    }
  };

  const onRegenerate = async (
    data: TechniqueModels.Params,
    { setSubmitting }: FormikHelpers<TechniqueModels.Params>,
  ) => {
    try {
      onBack();

      await techniqueModels.onReGenerate({
        ...data,
        uid: selected._id ?? '',
      });

      setSubmitting(false);
    } catch (error) {
      // ..
    }
  };

  const validate = yup.object().shape({
    solutions: yup.string().required(),
    models: yup.string().required(),
  });

  const onChangeText = debounce(async (text: string) => {
    setSelected({
      ...selected,
      data: text,
    });

    await techniqueModels.onUpdateData({
      data: text,
      uid: selected._id ?? '',
    });
  });

  const onBack = () => {
    setEdit(false);
    setSelected({});
  };

  const onDownloadHidden = async () => {
    await IAAPI.onTechniqueModelsDownload(selected._id!);
    await ia.onListSolutions();
  };

  const onDownloadWord = async () => {
    const blob = new Blob([clearMarkdown(ref.current?.getMarkdown())], {
      type: 'application/pdf',
    });

    FileSaver.saveAs(blob, `${selected.numberID}-analise-tecnica.doc`);

    onDownloadHidden();
  };

  useEffect(() => {
    techniqueModels.onList();
  }, []);

  useEffect(() => {
    if (
      techniqueModels.solutions.some(({ status }) => status === 'Processando')
    ) {
      setTimeout(() => {
        techniqueModels.onList();
      }, 10000);
    }
  }, [techniqueModels.solutions]);

  return (
    <Container>
      <Menu selected={2} />
      <Body>
        <Formik
          enableReinitialize
          validationSchema={validate}
          initialValues={{
            solutions: selected.solutions ?? '',
            models: selected.models ?? '',
          }}
          onSubmit={edit ? onRegenerate : onSubmit}
        >
          <ReactToPrint
            content={() => refPrint.current}
            documentTitle={`${selected.numberID}-analise-tecnica`}
          >
            <PrintContextConsumer>
              {({ handlePrint }) => (
                <TechniqueModelsForm
                  onDownloadWord={onDownloadWord}
                  onDownloadPDF={() => {
                    handlePrint();
                    onDownloadHidden();
                  }}
                  onBack={onBack}
                  enableBack={edit}
                />
              )}
            </PrintContextConsumer>
          </ReactToPrint>
        </Formik>

        <If condition={edit}>
          <div ref={refPrint} style={{ padding: 5 }}>
            <MDXEditor
              ref={ref}
              contentEditableClassName="contentEditableClassName"
              markdown={selected.data ?? ''}
              onChange={onChangeText}
              plugins={[
                headingsPlugin(),
                listsPlugin(),
                markdownShortcutPlugin(),
                thematicBreakPlugin(),
              ]}
            />
          </div>
        </If>

        <If condition={!edit}>
          <CardSolutionsList>
            {techniqueModels.solutions.map((data) => (
              <CardDataIA
                key={data._id}
                title={data.solutions}
                describe={data.models}
                status={data.status}
                numberID={data.numberID}
                onPress={() => {
                  setSelected(data);
                  setEdit(true);
                }}
              />
            ))}
          </CardSolutionsList>
        </If>
      </Body>
    </Container>
  );
};

export default observer(TechniqueModels);
