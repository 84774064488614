import React, { FC } from 'react';
import colors from '~/theme/colors';

type Props = {};

const Icon: FC<Props> = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
    <rect width="30" height="30" rx="5" fill={colors.primary.main} />
    <path
      d="M20.4605 14.2729C20.4082 14.3252 20.3462 14.3667 20.2779 14.395C20.2096 14.4233 20.1364 14.4379 20.0625 14.4379C19.9886 14.4379 19.9154 14.4233 19.8471 14.395C19.7788 14.3667 19.7168 14.3252 19.6645 14.2729L15.5625 10.1702V21.1875C15.5625 21.3366 15.5032 21.4797 15.3978 21.5852C15.2923 21.6907 15.1492 21.75 15 21.75C14.8508 21.75 14.7077 21.6907 14.6023 21.5852C14.4968 21.4797 14.4375 21.3366 14.4375 21.1875V10.1702L10.3355 14.2729C10.2299 14.3785 10.0868 14.4378 9.93751 14.4378C9.78824 14.4378 9.64509 14.3785 9.53954 14.2729C9.43399 14.1674 9.37469 14.0242 9.37469 13.875C9.37469 13.7257 9.43399 13.5825 9.53954 13.477L14.602 8.41449C14.6543 8.36219 14.7163 8.3207 14.7846 8.29239C14.8529 8.26408 14.9261 8.24951 15 8.24951C15.0739 8.24951 15.1471 8.26408 15.2154 8.29239C15.2837 8.3207 15.3457 8.36219 15.398 8.41449L20.4605 13.477C20.5128 13.5292 20.5543 13.5913 20.5826 13.6596C20.6109 13.7278 20.6255 13.801 20.6255 13.875C20.6255 13.9489 20.6109 14.0221 20.5826 14.0904C20.5543 14.1586 20.5128 14.2207 20.4605 14.2729Z"
      fill={colors.primary.light}
    />
  </svg>
);

export default Icon;
