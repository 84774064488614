import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { If, Menu, UpdateRequests } from '~/components';
import { device } from '~/theme/breakpoints';
import { useStores } from '~/utils';
import {
  Body,
  Container,
  Header,
  Row,
  SectionAdded,
  SectionBorder,
  Text,
  TextAdded,
  Title,
} from './styles';
import '@mdxeditor/editor/style.css';

const RequestsManager: FC = (): JSX.Element => {
  const { app, requests } = useStores();

  const onSeletUser = (user: Requests.All) => {
    requests.onSelectUser(user);
    app.onSetModal('UPDATE_REQUEST');
  };

  useEffect(() => {
    requests.allUsers();
  }, []);

  return (
    <>
      <Container>
        <Menu selected={3} />
        <Body>
          <Header>
            <Title>Gestão de Requisições</Title>
          </Header>

          <If condition={!device.isMobile}>
            <Row>
              <SectionAdded>
                <Text>Nome</Text>
              </SectionAdded>
              <SectionAdded>
                <Text>Email</Text>
              </SectionAdded>
              <SectionAdded>
                <Text>Requisições Bloqueadas</Text>
              </SectionAdded>
              <SectionAdded>
                <Text>Requisições em Uso</Text>
              </SectionAdded>
              <SectionAdded>
                <Text>Data do Uso</Text>
              </SectionAdded>
              <SectionAdded>
                <Text>Ações</Text>
              </SectionAdded>
            </Row>
          </If>

          {requests.all?.map((user) => (
            <SectionBorder key={user.fbUserID}>
              <SectionAdded>
                <TextAdded>{user.name}</TextAdded>
              </SectionAdded>

              <SectionAdded>
                <TextAdded>{user.email}</TextAdded>
              </SectionAdded>

              <SectionAdded>
                <TextAdded>{user?.tokens?.totalTokens || '0'}</TextAdded>
              </SectionAdded>

              <SectionAdded>
                <TextAdded>{user?.tokens?.reservedTokens || '0'}</TextAdded>
              </SectionAdded>

              <SectionAdded>
                <TextAdded>
                  {!!user?.tokens?.startDate && !!user?.tokens?.endDate
                    ? `${moment
                        .utc(user.tokens.startDate)
                        .format('DD/MM')} - ${moment
                        .utc(user.tokens.endDate)
                        .format('DD/MM')}`
                    : '-'}
                </TextAdded>
              </SectionAdded>

              <SectionAdded>
                <TextAdded roxy onClick={() => onSeletUser(user)}>
                  Atualizar Requisições
                </TextAdded>
              </SectionAdded>
            </SectionBorder>
          ))}
        </Body>
      </Container>

      <UpdateRequests />
    </>
  );
};

export default observer(RequestsManager);
