import React, { FC } from 'react';
import { Content } from './styles';

type Props = {
  children?: any;
};

const Public: FC<Props> = ({ children, ...rest }) => (
  <Content {...rest}>{children}</Content>
);

export default Public;
