import React, { FC } from 'react';
import { Arrow } from '~/assets/svg';
import { reduceString } from '~/utils';
import If from '../If';
import {
  Content,
  Description,
  Name,
  SectionDescription,
  TextID,
  TextProcessing,
} from './styles';

type Props = {
  numberID: number;
  title: string;
  describe: string;
  status: string;
  onPress?: () => void;
};

const CardDataIA: FC<Props> = ({
  numberID,
  title = '',
  describe = '',
  status,
  onPress,
}) => (
  <Content
    onClick={status === 'Finalizado' ? onPress : () => {}}
    style={{
      cursor: status === 'Finalizado' ? 'pointer' : 'default',
    }}
  >
    <SectionDescription>
      <TextProcessing processing={status !== 'Finalizado'}>
        {status}
      </TextProcessing>
      <TextID>{numberID}</TextID>
      <Name>{reduceString(title, 20)}</Name>
    </SectionDescription>

    <SectionDescription>
      <Description>{reduceString(describe, 50)}</Description>

      <If condition={status === 'Finalizado'}>
        <Arrow />
      </If>
    </SectionDescription>
  </Content>
);

export default CardDataIA;
