import React, { FC } from 'react';

type Props = {};

const Icon: FC<Props> = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="30" height="30" rx="5" fill="#ECECEE" />
    <path
      d="M13.4998 23.2494C13.4998 23.4483 13.4207 23.6391 13.2801 23.7797C13.1394 23.9204 12.9487 23.9994 12.7498 23.9994H6.74998C6.55107 23.9994 6.36031 23.9204 6.21966 23.7797C6.07902 23.6391 6 23.4483 6 23.2494V6.74998C6 6.55107 6.07902 6.36031 6.21966 6.21966C6.36031 6.07902 6.55107 6 6.74998 6H12.7498C12.9487 6 13.1394 6.07902 13.2801 6.21966C13.4207 6.36031 13.4998 6.55107 13.4998 6.74998C13.4998 6.94888 13.4207 7.13964 13.2801 7.28029C13.1394 7.42094 12.9487 7.49995 12.7498 7.49995H7.49995V22.4995H12.7498C12.9487 22.4995 13.1394 22.5785 13.2801 22.7191C13.4207 22.8598 13.4998 23.0505 13.4998 23.2494ZM23.78 14.4691L20.0302 10.7192C19.8894 10.5785 19.6986 10.4994 19.4996 10.4994C19.3005 10.4994 19.1097 10.5785 18.969 10.7192C18.8282 10.8599 18.7492 11.0508 18.7492 11.2498C18.7492 11.4488 18.8282 11.6397 18.969 11.7804L21.4392 14.2497H12.7498C12.5509 14.2497 12.3601 14.3287 12.2195 14.4694C12.0788 14.61 11.9998 14.8008 11.9998 14.9997C11.9998 15.1986 12.0788 15.3894 12.2195 15.53C12.3601 15.6707 12.5509 15.7497 12.7498 15.7497H21.4392L18.969 18.219C18.8282 18.3597 18.7492 18.5506 18.7492 18.7496C18.7492 18.9486 18.8282 19.1395 18.969 19.2802C19.1097 19.4209 19.3005 19.5 19.4996 19.5C19.6986 19.5 19.8894 19.4209 20.0302 19.2802L23.78 15.5303C23.8498 15.4607 23.9051 15.3779 23.9428 15.2869C23.9806 15.1959 24 15.0983 24 14.9997C24 14.9011 23.9806 14.8036 23.9428 14.7125C23.9051 14.6215 23.8498 14.5387 23.78 14.4691Z"
      fill="#3C3940"
    />
  </svg>
);

export default Icon;
