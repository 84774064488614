import { Request } from '~/services';

export const onSolutionsGenerate = async (
  params: Solution.Params,
): Promise<Solution.Response[]> => {
  try {
    const { data } = await Request.post('/ia/solutions', params);
    return data;
  } catch (error) {
    return [];
  }
};

export const onSolutionsReGenerate = async ({
  context,
  entity,
  problem,
  solutions,
  uid,
}: Solution.ParamsRegenerate): Promise<Solution.Response[]> => {
  try {
    const { data } = await Request.put('/ia/solutions', {
      context,
      entity,
      problem,
      solutions,
      uid,
    });
    return data;
  } catch (error) {
    return [];
  }
};

export const onListSolutions = async (): Promise<Solution.Response[]> => {
  const { data } = await Request.get('/ia/solutions');
  return data;
};

export const onUpdateDataSolutions = async ({
  data,
  uid,
}: Solution.EditData): Promise<Solution.Response[]> => {
  try {
    const { data: response } = await Request.put('/ia/solutions/data', {
      data,
      uid,
    });
    return response;
  } catch (error) {
    return [];
  }
};

// Functional Models

export const onFunctionalModelsGenerate = async (
  params: FunctionalModels.Params,
): Promise<FunctionalModels.Response[]> => {
  try {
    const { data } = await Request.post('/ia/functional-models', params);
    return data;
  } catch (error) {
    return [];
  }
};

export const onFunctionalModelsReGenerate = async ({
  objective,
  resource,
  solutions,
  uid,
}: FunctionalModels.ParamsRegenerate): Promise<FunctionalModels.Response[]> => {
  try {
    const { data } = await Request.put('/ia/functional-models', {
      objective,
      resource,
      solutions,
      uid,
    });
    return data;
  } catch (error) {
    return [];
  }
};

export const onListFunctionalModels = async (): Promise<
  FunctionalModels.Response[]
> => {
  const { data } = await Request.get('/ia/functional-models');
  return data;
};

export const onUpdateDataFunctionalModels = async ({
  data,
  uid,
}: FunctionalModels.EditData): Promise<FunctionalModels.Response[]> => {
  try {
    const { data: response } = await Request.put('/ia/functional-models/data', {
      data,
      uid,
    });
    return response;
  } catch (error) {
    return [];
  }
};

// Technique Models

export const onTechniqueModelsGenerate = async (
  params: TechniqueModels.Params,
): Promise<TechniqueModels.Response[]> => {
  try {
    const { data } = await Request.post('/ia/technique-models', params);
    return data;
  } catch (error) {
    return [];
  }
};

export const onTechniqueModelsReGenerate = async ({
  models,
  solutions,
  uid,
}: TechniqueModels.ParamsRegenerate): Promise<TechniqueModels.Response[]> => {
  try {
    const { data } = await Request.put('/ia/technique-models', {
      models,
      solutions,
      uid,
    });
    return data;
  } catch (error) {
    return [];
  }
};

export const onListTechniqueModels = async (): Promise<
  TechniqueModels.Response[]
> => {
  const { data } = await Request.get('/ia/technique-models');
  return data;
};

export const onUpdateDataTechniqueModels = async ({
  data,
  uid,
}: TechniqueModels.EditData): Promise<TechniqueModels.Response[]> => {
  try {
    const { data: response } = await Request.put('/ia/technique-models/data', {
      data,
      uid,
    });
    return response;
  } catch (error) {
    return [];
  }
};

export const onSolutionsDownload = async (uid: string): Promise<void> => {
  try {
    await Request.post('/ia/solutions/download', {
      uid,
    });
  } catch (error) {
    // ..
  }
};

export const onTechniqueModelsDownload = async (uid: string): Promise<void> => {
  try {
    await Request.post('/ia/technique-models/download', {
      uid,
    });
  } catch (error) {
    // ..
  }
};

export const onFunctionalModelsDownload = async (
  uid: string,
): Promise<void> => {
  try {
    await Request.post('/ia/functional-models/download', {
      uid,
    });
  } catch (error) {
    // ..
  }
};
