import axios from 'axios';
import { isEmpty } from 'lodash';
import { Firebase } from '~/services';
import Alert from './toast';

export const myIP = async () => {
  try {
    const { data } = await axios.get('https://ipapi.co/json/', {
      timeout: 1500,
    });

    return `${data.ip}, ${data.city}, ${data.region}, ${data.country} - ${data.org}`;
  } catch (error) {
    return '';
  }
};

export const interceptErrors = (error: any) => {
  if (error?.response?.status < 400) return;

  if (error?.response?.data === 'no authorization') {
    const fb = new Firebase();

    fb.signOut();

    window.location.reload();

    return;
  }

  try {
    if (!isEmpty(error?.response?.data)) {
      const data = JSON.parse(error?.response?.data);
      if (data?.msg) {
        Alert({
          message: data.msg,
          type: 'error',
        });

        return;
      }
    }
  } catch (error) {
    // ...
  }
  if (error?.response && error?.response?.data) {
    const { msg } = error.response.data;

    if (!msg) return;

    Alert({
      message: msg,
      type: 'error',
    });
  }
};

export const debounce = <T = any>(callback: (E: T) => void, timeout = 1000) => {
  let timer: any;
  return (args: any) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      callback(args);
    }, timeout);
  };
};

export const clearMarkdown = (content = ''): string =>
  content
    .replaceAll('<br />', '\n')
    .replaceAll('<br/>', '\n')
    .replaceAll('**', '')
    .replaceAll('*', '')
    .replaceAll('#', '')
    .replaceAll('  ', '')
    .replaceAll('·', '\n -');

export const reduceString = (text: string, length = 100): string => {
  if (text.length <= length) return text;

  return `${text.slice(0, length)}...`;
};
