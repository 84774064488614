import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { Back } from '~/assets/svg';
import { device } from '~/theme/breakpoints';
import Button from '../Button';
import If from '../If';
import {
  ButtonText,
  ContentForm,
  Header,
  Line,
  TextFields,
  Title,
  WrapperButtons,
} from './styles';

type Props = {
  onBack: () => void;
  enableBack?: boolean;
  onDownloadPDF: () => void;
  onDownloadWord: () => void;
};

const SolutionsForm: FC<Props> = ({
  onBack,
  enableBack = false,
  onDownloadPDF,
  onDownloadWord,
}) => {
  const { submitForm, values, handleChange, isSubmitting, errors, touched } =
    useFormikContext<Solution.Params>();

  return (
    <ContentForm>
      <Header>
        <If condition={enableBack}>
          <Title>
            <Back onPress={onBack} />
            Soluções Orientado por IA
          </Title>
        </If>

        <If condition={!enableBack}>
          <Title>Soluções Orientado por IA</Title>
        </If>

        <WrapperButtons>
          <If condition={enableBack}>
            <ButtonText onClick={onDownloadPDF}>Baixar em PDF</ButtonText>
            <ButtonText onClick={onDownloadWord}>Baixar em Word</ButtonText>
          </If>

          <If condition={!device.isMobile}>
            <Button onPress={submitForm}>
              <If condition={isSubmitting}>
                <CircularProgress size={25} style={{ color: 'white' }} />
              </If>
              <If condition={!isSubmitting}> Gerar Solução</If>
            </Button>
          </If>
        </WrapperButtons>
      </Header>

      <Line>
        <TextFields
          name="entity"
          value={values.entity}
          onChange={handleChange('entity')}
          placeholder="Entidade"
          error={touched.entity && !!errors.entity}
        />

        <TextFields
          name="context"
          value={values.context}
          onChange={handleChange('context')}
          placeholder="Contexto e objetivo de negócio"
          error={touched.context && !!errors.context}
        />
      </Line>

      <Line>
        <TextFields
          name="problem"
          value={values.problem}
          onChange={handleChange('problem')}
          placeholder="Problema"
          error={touched.problem && !!errors.problem}
        />

        <TextFields
          name="solutions"
          value={values.solutions}
          onChange={handleChange('solutions')}
          placeholder="Solução desejada"
          error={touched.solutions && !!errors.solutions}
        />
      </Line>

      <br />

      <If condition={device.isMobile}>
        <Button onPress={submitForm}>
          <If condition={isSubmitting}>
            <CircularProgress size={25} style={{ color: 'white' }} />
          </If>
          <If condition={!isSubmitting}> Gerar Solução</If>
        </Button>
      </If>
    </ContentForm>
  );
};

export default SolutionsForm;
