import React, { FC, useEffect, useState } from 'react';
import { Collapse } from 'react-collapse';
import { Icon } from '~/assets/img';
import { Logout, MenuIcon } from '~/assets/svg';
import { Firebase } from '~/services';
import { device } from '~/theme/breakpoints';
import { OPTIONS_MENU, PROFILE, observer, useStores } from '~/utils';
import If from '../If';
import ModalManagerRequests from '../ModalManagerRequests';
import {
  Content,
  Img,
  Item,
  ItemName,
  Options,
  RequestClickedText,
  RequestStatusText,
  UserName,
  WrapperUser,
} from './styles';

type Props = {
  selected?: number;
};

const firebase = new Firebase();

const Menu: FC<Props> = ({ selected }) => {
  const { user, routing, app, requests } = useStores();

  const onUpdateRequestAmount = () => app.onSetModal('MANAGER_REQUEST');

  const [opened, setOpened] = useState(false);

  const onNavigate = (route: string) => routing.push(route);

  useEffect(() => {
    requests.onFetchStatusTokens();
  }, []);

  const menuItens = OPTIONS_MENU.filter(
    ({ restrict }) => !(!!restrict && user.data.type !== PROFILE.ADMIN),
  );

  return (
    <>
      <Content>
        <WrapperUser>
          <Img src={Icon} />
          <If condition={!device.isMobile}>
            <UserName>
              {user.data.name.substring(0, 40)}
              {user.data.name.length > 40 ? '...' : ''}
            </UserName>
          </If>

          <If condition={device.isMobile}>
            <MenuIcon onClick={() => setOpened(!opened)} />
          </If>
        </WrapperUser>

        <If
          condition={
            !!requests.data?.totalTokens || !!requests.data?.reservedTokens
          }
        >
          <RequestStatusText>
            Requisicoes: <b>{requests.data?.totalTokens}</b>
          </RequestStatusText>
          <RequestStatusText>
            Requisicoes em uso: <b>{requests.data?.reservedTokens || '0'}</b>
          </RequestStatusText>

          <RequestClickedText onClick={onUpdateRequestAmount}>
            Gerenciar Requisições
          </RequestClickedText>
        </If>

        <If condition={device.isMobile}>
          <Collapse isOpened={opened} style={{ width: '100%' }}>
            <>
              <Options>
                {menuItens.map((option, idx) => (
                  <Item
                    key={option.name}
                    selected={selected === idx}
                    onClick={() => onNavigate(option.route)}
                  >
                    <ItemName selected={selected === idx}>
                      {option.name}
                    </ItemName>
                    {option.icon}
                  </Item>
                ))}
              </Options>

              <Item
                disableHover
                onClick={() => {
                  firebase.signOut();
                  window.location.reload();
                }}
              >
                <ItemName>Sair da Conta</ItemName>
                <Logout />
              </Item>
            </>
          </Collapse>
        </If>

        <If condition={!device.isMobile}>
          <>
            <Options>
              {menuItens.map((option, idx) => (
                <Item
                  key={option.name}
                  selected={selected === idx}
                  onClick={() => onNavigate(option.route)}
                >
                  <ItemName selected={selected === idx}>{option.name}</ItemName>
                  {option.icon}
                </Item>
              ))}
            </Options>

            <Item
              disableHover
              onClick={() => {
                firebase.signOut();
                window.location.reload();
              }}
            >
              <ItemName>Sair da Conta</ItemName>
              <Logout />
            </Item>
          </>
        </If>
      </Content>

      <ModalManagerRequests />
    </>
  );
};

export default observer(Menu);
