import { getTheme, ifStyle, pxToRem, styled } from '~/utils';

const smallSpacing = getTheme('smallSpacing');
const secondaryContrast = getTheme('secondary.contrast');
const smallRadius = getTheme('smallRadius');
const primaryDark = getTheme('primary.dark');
const primaryMain = getTheme('primary.main');
const failure = getTheme('failure');
const primaryContrast = getTheme('primary.contrast');

const isError = ifStyle('error');
const isFullWidth = ifStyle('fullWidth');

const inMobile = getTheme('inMobile');
const inTablet = getTheme('inTablet');

export const ContentForm = styled.section`
  width: 100%;
  flex-direction: column;
  border-bottom: 1px solid ${secondaryContrast}50;
  padding-bottom: ${pxToRem(35)};
  align-items: center;
`;

export const Line = styled.section`
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media ${inMobile} {
    width: 100%;
    flex-direction: column;
  }
`;

type Props = {
  error?: boolean;
  fullWidth?: boolean;
};

export const TextFields = styled.textarea<Props>`
  width: ${isFullWidth(100, 49.5)}%;
  border: 0.4px solid ${secondaryContrast}50;
  border-radius: ${smallRadius};
  height: ${pxToRem(80)};
  font-size: ${pxToRem(14)};
  margin-top: ${smallSpacing};
  padding: ${smallSpacing};
  border: ${isError(1)}px solid ${failure};

  @media ${inMobile} {
    min-width: 100%;
  }
`;

export const Header = styled.section`
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @media ${inMobile} {
    flex-direction: column;
  }

  @media ${inTablet} {
    flex-direction: column;
  }
`;

export const Title = styled.h1`
  font-weight: 400;
  color: ${primaryDark};
  display: flex;
  align-items: center;
  font-size: 1.2rem;

  @media ${inMobile} {
    font-size: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  @media ${inTablet} {
    width: 100%;
  }
`;

export const ButtonText = styled.h5`
  cursor: pointer;
  display: flex;
  font-size: ${pxToRem(14)};
  align-items: center;
  width: ${pxToRem(120)};
  text-align: center;
  margin-right: ${smallSpacing};
  font-weight: 400;
  color: ${primaryContrast};

  &:hover {
    color: ${primaryMain};
    transition: 0.3s;
  }
`;

export const WrapperButtons = styled.section`
  @media ${inMobile} {
    margin-top: ${smallSpacing};
    width: 100%;
  }

  @media ${inTablet} {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;
