import React, { FC } from 'react';
import { Button } from './styles';

type Props = {
  onPress: () => void;
  children: any;
};

const ButtonComp: FC<Props> = ({ onPress = () => {}, children }) => (
  <Button onClick={onPress}>{children}</Button>
);

export default ButtonComp;
