import React, { FC } from 'react';
import { observer } from 'mobx-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import { MODAL, toOnlyNumbers, useFormikContext, useStores } from '~/utils';
import If from '../If';
import {
  Button,
  Fields,
  Paper,
  Paragraph,
  TextError,
  TextFields,
  Title,
} from './styles';

const ManagerRequests: FC = () => {
  const { app } = useStores();

  const {
    submitForm,
    values,
    handleChange,
    isSubmitting,
    setFieldValue,
    errors,
    touched,
  } = useFormikContext<Requests.ManagerRequessts>();

  return (
    <Modal
      open={app.modal === MODAL.MANAGER_REQUEST}
      onClose={() => app.onSetModal(null)}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Fade in={app.modal === MODAL.MANAGER_REQUEST} timeout={500}>
        <Paper>
          <Title>Atualizar Requisições</Title>
          <Paragraph>
            Neste formulário você pode indicar a quantidade de requisições que
            deseja utilizar e a data.
          </Paragraph>

          <Fields>
            <TextFields
              name="amount"
              value={values.amount}
              placeholder="Quantidade para utilizar"
              onChange={(event) => {
                setFieldValue('amount', toOnlyNumbers(event.target.value));
              }}
            />
            <TextError>
              {errors.amount && touched.amount && errors.amount}{' '}
            </TextError>

            <TextField
              label="De"
              type="date"
              style={{ marginTop: 10, backgroundColor: 'white' }}
              onChange={(event) => {
                const { value } = event.target;
                setFieldValue('from', value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextError>{errors.from && touched.from && errors.from} </TextError>

            <TextField
              style={{ marginTop: 10, backgroundColor: 'white' }}
              label="Até"
              type="date"
              onChange={(event) => {
                const { value } = event.target;
                setFieldValue('to', value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Fields>
          <TextError>{errors.to && touched.to && errors.to} </TextError>

          <Button
            onClick={(e) => {
              e.preventDefault();
              submitForm();
            }}
            type="submit"
          >
            <If condition={isSubmitting}>
              <CircularProgress size={25} style={{ color: 'white' }} />
            </If>
            <If condition={!isSubmitting}>Finalizar</If>
          </Button>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default observer(ManagerRequests);
