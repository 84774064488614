import { getTheme, pxToRem, styled } from '~/utils';

const primaryMain = getTheme('primary.main');
const primaryLight = getTheme('primary.light');
const smallSpacing = getTheme('smallSpacing');
const inMobile = getTheme('inMobile');

export const Button = styled.button`
  border: 0;
  font-size: ${pxToRem(14)};
  text-align: center;
  width: ${pxToRem(160)};
  height: ${pxToRem(50)};
  border-radius: ${pxToRem(100)};
  background-color: ${primaryMain};
  color: ${primaryLight};
  padding: ${smallSpacing};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  box-shadow: 0px 0px 4px ${primaryMain}50;
  cursor: pointer;

  @media ${inMobile} {
    font-size: ${pxToRem(12)};
    width: ${pxToRem(140)};
    height: ${pxToRem(45)};
  }
`;
