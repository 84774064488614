import { action, makeObservable, observable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { IAAPI } from '~/services/api';
import Root from './index';

export default class IAStore {
  root: typeof Root;

  @observable
  solutions: Solution.Response[] = [];

  constructor(root: typeof Root) {
    this.root = root;
    makeObservable(this);

    makePersistable(this, {
      name: 'IA',
      properties: ['solutions'],
      storage: window.localStorage,
    });
  }

  @action
  onListSolutions = async (): Promise<boolean> => {
    try {
      const solutions = await IAAPI.onListSolutions();

      this.solutions = solutions;

      return true;
    } catch (error) {
      return false;
    }
  };

  @action
  onSolutionsGenerate = async (data: Solution.Params): Promise<boolean> => {
    try {
      const solutions = await IAAPI.onSolutionsGenerate(data);
      if (!solutions.length) return false;

      this.solutions = solutions;

      return true;
    } catch (error) {
      return false;
    }
  };

  @action
  onSolutionsReGenerate = async (
    data: Solution.ParamsRegenerate,
  ): Promise<boolean> => {
    try {
      this.solutions = this.solutions.map((solution) => {
        if (solution._id === data.uid) {
          return {
            ...solution,
            status: 'Processando',
          };
        }
        return solution;
      });

      const solutions = await IAAPI.onSolutionsReGenerate(data);
      if (!solutions.length) return false;

      this.solutions = solutions;

      return true;
    } catch (error) {
      return false;
    }
  };

  @action
  onUpdateDataSolutions = async (data: Solution.EditData): Promise<void> => {
    try {
      const solutions = await IAAPI.onUpdateDataSolutions(data);
      this.solutions = solutions;
    } catch (error) {
      // ..
    }
  };
}
