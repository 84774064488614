import React, { FC } from 'react';
import { Formik } from 'formik';
import { Path } from '~/routes/routes.path';
import { REGEX_PWD, useStores, yup } from '~/utils';
import Login from './Login';

const validate = yup.object().shape({
  email: yup.string().email().required('O campo de email é obrigatório.'),
  pwd: yup
    .string()
    .required('A senha é obrigatória.')
    .matches(
      REGEX_PWD,
      'A senha deve conter 8 caracteres,letra maiúscula, minúscula, números e um símbolo especiais.',
    ),
});

const LoginContainer: FC = () => {
  const { user, routing } = useStores();

  const onSubmit = async ({ email, pwd }: User.LoginParams) => {
    const logged = await user.login({ email, pwd });

    if (logged) routing.replace(Path.Solutions);
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{ email: '', pwd: '' }}
      validationSchema={validate}
    >
      <Login />
    </Formik>
  );
};

export default LoginContainer;
