import { styled } from '~/utils';

export const Content = styled.section`
  flex: 1;
  width: 100%;
  background: rgb(230, 239, 248);
  background: linear-gradient(
    90deg,
    rgba(230, 239, 248, 1) 35%,
    rgba(246, 249, 252, 1) 100%
  );
`;
