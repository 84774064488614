import React, { FC, useState } from 'react';
import { observer } from 'mobx-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { MODAL, useFormikContext, useStores } from '~/utils';
import If from '../If';
import {
  Bold,
  Button,
  Fields,
  Paper,
  Paragraph,
  TextError,
  TextFields,
  Title,
} from './styles';

const Login: FC = () => {
  const { app } = useStores();
  const [showPassword, setShowPassword] = useState(false);

  const { submitForm, values, handleChange, isSubmitting, errors, touched } =
    useFormikContext<User.LoginParams>();

  return (
    <Modal
      open={app.modal === MODAL.LOGIN}
      onClose={() => app.onSetModal(null)}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Fade in={app.modal === MODAL.LOGIN} timeout={500}>
        <Paper>
          <Title>Entrar</Title>
          <Paragraph>
            Desbloqueie insights e impulsione a inovação com EvyAI.
          </Paragraph>

          {/* <Paragraph>
            Não tem uma conta ? &nbsp;
            <Bold onClick={() => app.onSetModal('REGISTER')}>Criar conta</Bold>
          </Paragraph> */}

          <Fields>
            <TextFields
              name="email"
              value={values.email}
              placeholder="Email"
              type="email"
              onChange={handleChange('email')}
            />

            <TextFields
              placeholder="Senha"
              name="pwd"
              value={values.pwd}
              onChange={handleChange('pwd')}
              type="password"
            />
          </Fields>

          <TextError>
            {errors.email && touched.email && errors.email} &nbsp;
            {errors.pwd && touched.pwd && errors.pwd}
          </TextError>

          <Button
            onClick={(e) => {
              e.preventDefault();
              submitForm();
            }}
            type="submit"
          >
            <If condition={isSubmitting}>
              <CircularProgress size={25} style={{ color: 'white' }} />
            </If>
            <If condition={!isSubmitting}>Continuar</If>
          </Button>

          <Paragraph>
            Esqueceu sua senha ? &nbsp;
            <Bold onClick={() => app.onSetModal('RECOVERY_PASSWORD')}>
              Recuperar senha
            </Bold>
          </Paragraph>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default observer(Login);
