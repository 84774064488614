import React, { FC } from 'react';

type Props = {
  onPress?: () => void;
};

const Icon: FC<Props> = ({ onPress }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ cursor: 'pointer' }}
    onClick={onPress}
  >
    <path
      d="M11.6154 19.1157L24.1154 6.61566C24.2316 6.49952 24.3694 6.4074 24.5212 6.34455C24.6729 6.28169 24.8355 6.24934 24.9998 6.24934C25.164 6.24934 25.3267 6.28169 25.4784 6.34455C25.6302 6.4074 25.768 6.49952 25.8842 6.61566C26.0003 6.7318 26.0924 6.86967 26.1553 7.02142C26.2181 7.17316 26.2505 7.33579 26.2505 7.50004C26.2505 7.66428 26.2181 7.82692 26.1553 7.97866C26.0924 8.1304 26.0003 8.26827 25.8842 8.38441L14.267 20L25.8842 31.6157C26.1187 31.8502 26.2505 32.1683 26.2505 32.5C26.2505 32.8317 26.1187 33.1499 25.8842 33.3844C25.6496 33.619 25.3315 33.7507 24.9998 33.7507C24.6681 33.7507 24.35 33.619 24.1154 33.3844L11.6154 20.8844C11.4992 20.7683 11.407 20.6305 11.3441 20.4787C11.2812 20.327 11.2488 20.1643 11.2488 20C11.2488 19.8358 11.2812 19.6731 11.3441 19.5214C11.407 19.3696 11.4992 19.2318 11.6154 19.1157Z"
      fill="#6242E4"
    />
  </svg>
);

export default Icon;
