import React, { FC } from 'react';
import { observer } from 'mobx-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { MODAL, useFormikContext, useStores } from '~/utils';
import If from '../If';
import {
  Bold,
  Button,
  Fields,
  Paper,
  Paragraph,
  TextFields,
  Title,
} from './styles';

const RecoveryPassword: FC = () => {
  const { app } = useStores();
  const { submitForm, values, handleChange, isSubmitting, errors, touched } =
    useFormikContext<User.RecoveryPwd>();

  return (
    <Modal
      open={app.modal === MODAL.RECOVERY_PASSWORD}
      onClose={() => app.onSetModal(null)}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Fade in={app.modal === MODAL.RECOVERY_PASSWORD} timeout={500}>
        <Paper>
          <Title>Recuperar Senha</Title>
          <Paragraph>
            Desbloqueie insights e impulsione a inovação com EvyAI.
          </Paragraph>

          <Paragraph>
            Já tem uma conta ? &nbsp;
            <Bold onClick={() => app.onSetModal('LOGIN')}>Acessar conta</Bold>
          </Paragraph>

          <Fields>
            <TextFields
              name="email"
              value={values.email}
              placeholder="Email"
              type="email"
              onChange={handleChange('email')}
            />
          </Fields>

          <Button onClick={submitForm}>
            <If condition={isSubmitting}>
              <CircularProgress size={25} style={{ color: 'white' }} />
            </If>
            <If condition={!isSubmitting}>Continuar</If>
          </Button>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default observer(RecoveryPassword);
