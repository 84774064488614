import { ScenePublic } from '~/components';
import { getTheme, pxToRem, styled } from '~/utils';

const sceneSpacing = getTheme('sceneSpacing');
const smallSpacing = getTheme('smallSpacing');

const primaryDark = getTheme('primary.dark');
const primaryMain = getTheme('primary.main');
const inMobile = getTheme('inMobile');

export const Container = styled(ScenePublic)`
  width: 100%;
  height: 100vh;
  padding: ${sceneSpacing};
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${inMobile} {
    padding: ${smallSpacing};
  }
`;

export const Header = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${pxToRem(90)};
  padding: 0 ${sceneSpacing};
  position: fixed;
  top: ${pxToRem(10)};
  right: 0;
  left: 0;

  @media ${inMobile} {
    padding: 0 ${smallSpacing};
    top: ${smallSpacing};
  }
`;

export const Img = styled.img`
  width: ${pxToRem(90)};
  height: ${pxToRem(90)};
  object-fit: contain;

  @media ${inMobile} {
    width: ${pxToRem(80)};
    height: ${pxToRem(80)};
  }
`;

export const Body = styled.section`
  width: 100%;
  height: 40vh;
  margin: 0 auto;
  align-self: center;

  flex-direction: column;
  @media ${inMobile} {
    height: 60%;
  }
`;

export const Title = styled.h1`
  font-size: ${pxToRem(80)};
  font-weight: bold;
  color: #3194fb;
  font-family: 'Squada One';
`;

export const Phrase = styled.h2`
  font-size: ${pxToRem(35)};
  font-weight: 400;
  font-family: 'Squada One';
  color: ${primaryMain};
  margin-top: -${pxToRem(20)};
  margin-bottom: ${pxToRem(10)};

  @media ${inMobile} {
    margin-top: ${pxToRem(10)};
    font-size: ${pxToRem(40)};
    line-height: ${pxToRem(50)};
    margin-bottom: ${pxToRem(20)};
  }
`;

export const Paragraph = styled.p`
  width: 70%;
  font-size: ${pxToRem(25)};
  font-weight: 300;
  margin-bottom: ${pxToRem(25)};

  @media ${inMobile} {
    font-size: ${pxToRem(20)};
    width: 100%;
  }
`;
