import React, { FC, useState } from 'react';
import { observer } from 'mobx-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { EyeIcon } from '~/assets/svg';
import { MODAL, useFormikContext, useStores } from '~/utils';
import If from '../If';
import {
  Bold,
  Button,
  Fields,
  Paper,
  Paragraph,
  SectionIcon,
  SectionPassword,
  TextError,
  TextFields,
  Title,
} from './styles';

const Register: FC = () => {
  const { app } = useStores();
  const [showPassword, setShowPassword] = useState(false);

  const { submitForm, values, handleChange, isSubmitting, errors, touched } =
    useFormikContext<User.CreateParams>();

  return (
    <Modal
      open={app.modal === MODAL.REGISTER}
      onClose={() => app.onSetModal(null)}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Fade in={app.modal === MODAL.REGISTER} timeout={500}>
        <Paper>
          <Title>Criar conta</Title>
          <Paragraph>
            Desbloqueie insights e impulsione a inovação com EvyAI.
          </Paragraph>

          <Paragraph>
            Já tem uma conta ? &nbsp;
            <Bold onClick={() => app.onSetModal('LOGIN')}>Acessar conta</Bold>
          </Paragraph>

          <Fields>
            <TextFields
              name="name"
              value={values.name}
              placeholder="Nome Completo"
              onChange={handleChange('name')}
            />

            <TextFields
              name="email"
              value={values.email}
              placeholder="Email"
              type="email"
              onChange={handleChange('email')}
            />

            <SectionPassword>
              <TextFields
                placeholder="Senha"
                name="pwd"
                value={values.pwd}
                onChange={handleChange('pwd')}
                type={!showPassword ? 'password' : 'text'}
              />

              <SectionIcon onClick={() => setShowPassword((v) => !v)}>
                <EyeIcon open={!showPassword} />
              </SectionIcon>
            </SectionPassword>
          </Fields>

          <TextError>
            {errors.email && touched.email && errors.email} &nbsp;
            {errors.pwd && touched.pwd && errors.pwd}
            {errors.name && touched.name && errors.name}
          </TextError>

          <Button onClick={submitForm}>
            <If condition={isSubmitting}>
              <CircularProgress size={25} style={{ color: 'white' }} />
            </If>
            <If condition={!isSubmitting}>Continuar</If>
          </Button>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default observer(Register);
