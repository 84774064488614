import Axios from 'axios';
import { interceptErrors } from '~/utils';
import { interceptorsAuthHeaders } from './interceptors';

const baseURL = process.env.REACT_APP_API_URL;
const xToken = process.env.REACT_APP_X_TOKEN;

export const request = Axios.create({
  baseURL,
  headers: {
    'x-token': xToken,
  },
  timeout: 60000,
});

request.interceptors.request.use(interceptorsAuthHeaders);
request.interceptors.response.use(
  (rsp) => rsp,
  (error) => {
    interceptErrors(error);
    return Promise.reject(error);
  },
);
export default request;
