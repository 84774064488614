export default {
  failure: '#E56657',
  warning: '#FABD59',
  info: '#2D9CDB',
  success: '#27AE60',

  disabled: {
    light: '#ffffff',
    main: '#fff',
    dark: '#fff',
    contrast: '#4f4f4f',
  },
  primary: {
    light: '#FAFAFA',
    main: '#8B00F8',
    dark: '#21213C',
    contrast: '#3C3940',
  },
  secondary: {
    light: '#F7F3FC',
    main: '#fff',
    dark: '#21213C',
    contrast: '#586376',
  },
  tertiary: {
    light: '#B8B8B8',
    main: '#F6F9FC',
    dark: '#fff',
    contrast: '#FFFFFF',
  },
  accent: {
    light: '#fff',
    main: '#fff',
    dark: '#fff',
    contrast: '#ffffff',
  },
} as ColorType;
