import { action, makeObservable, observable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { IAAPI } from '~/services/api';
import Root from './index';

export default class Store {
  root: typeof Root;

  @observable
  solutions: TechniqueModels.Response[] = [];

  constructor(root: typeof Root) {
    this.root = root;
    makeObservable(this);

    makePersistable(this, {
      name: 'TechniqueModels',
      properties: ['solutions'],
      storage: window.localStorage,
    });
  }

  @action
  onList = async (): Promise<boolean> => {
    try {
      const solutions = await IAAPI.onListTechniqueModels();

      this.solutions = solutions;

      return true;
    } catch (error) {
      return false;
    }
  };

  @action
  onGenerate = async (data: TechniqueModels.Params): Promise<boolean> => {
    try {
      const solutions = await IAAPI.onTechniqueModelsGenerate(data);
      if (!solutions.length) return false;

      this.solutions = solutions;

      return true;
    } catch (error) {
      return false;
    }
  };

  @action
  onReGenerate = async (
    data: TechniqueModels.ParamsRegenerate,
  ): Promise<boolean> => {
    try {
      this.solutions = this.solutions.map((solution) => {
        if (solution._id === data.uid) {
          return {
            ...solution,
            status: 'Processando',
          };
        }
        return solution;
      });

      const solutions = await IAAPI.onTechniqueModelsReGenerate(data);
      if (!solutions.length) return false;

      this.solutions = solutions;

      return true;
    } catch (error) {
      return false;
    }
  };

  @action
  onUpdateData = async (data: TechniqueModels.EditData): Promise<void> => {
    try {
      const solutions = await IAAPI.onUpdateDataTechniqueModels(data);
      this.solutions = solutions;
    } catch (error) {
      // ..
    }
  };
}
