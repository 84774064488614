import { Analytics, getAnalytics, logEvent } from 'firebase/analytics';
import { FirebaseApp, initializeApp } from 'firebase/app';
import {
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';

import {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
} from '~/utils';
import Alert from '~/utils/helpers/toast';
import Storage from './storage';

const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: REACT_APP_FIREBASE_APP_ID,
  measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID,
};

class Firebase {
  app: FirebaseApp;

  analytics: Analytics;

  constructor() {
    this.app = initializeApp(firebaseConfig);
    this.analytics = getAnalytics(this.app);
  }

  onAuthStateChanged = () => {
    const auth = getAuth();

    return onAuthStateChanged(auth, async (user) => {
      if (user && user.emailVerified) {
        if (!user.emailVerified) return;

        const tk = await user.getIdToken();
        Storage.setToken(tk);
      } else {
        Storage.clearStorage();
        this.signOut();
      }
    });
  };

  onCreateUser = async ({
    email,
    pwd,
  }: Firebase.Create): Promise<string | null> => {
    try {
      const auth = getAuth();

      const { user } = await createUserWithEmailAndPassword(auth, email, pwd);

      const id = await user.getIdToken();

      await sendEmailVerification(user);

      return id || null;
    } catch (error) {
      return null;
    }
  };

  onSignIn = async ({ email, pwd }: Firebase.Create) => {
    try {
      const auth = getAuth();

      const { user } = await signInWithEmailAndPassword(auth, email, pwd);

      const id = await user.getIdToken();

      return id || null;
    } catch (error) {
      Alert({
        message: 'Usuário ou senha inválidos.',
      });
      return null;
    }
  };

  onResetPassword = async (email: string) => {
    try {
      const auth = getAuth();

      await sendPasswordResetEmail(auth, email);

      return true;
    } catch (error) {
      return false;
    }
  };

  logEvent = ({ name, params }: any) => {
    logEvent(name, params);
  };

  signOut = () => {
    const auth = getAuth();

    signOut(auth);

    Storage.clearStorage();
  };
}

export default Firebase;
