import React, { FC } from 'react';
import { Formik } from 'formik';
import { observer, useStores } from '~/utils';
import UpdateRequests from './UpdateRequests';

const UpdateRequestsContainer: FC = () => {
  const { app, requests } = useStores();

  const onSubmit = async ({ amount }: Requests.FormUpdateAmount) => {
    if (!amount) return;
    await requests.onSetTokens({ amount });
    app.onSetModal(null);
  };

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={{ amount: requests.editing?.tokens?.totalTokens || '' }}
    >
      <UpdateRequests />
    </Formik>
  );
};

export default observer(UpdateRequestsContainer);
