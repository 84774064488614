import { isEmpty } from 'lodash';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { toast } from 'react-toastify';
import { RequestsAPI } from '~/services/api';
import { PROFILE } from '~/utils';
import Root from './index';

export default class Requests {
  root: typeof Root;

  @observable
  editing: Partial<Requests.All> = {};

  @observable
  all: Requests.All[] = [];

  @observable
  data: Requests.StatusUser | null = {
    totalTokens: '',
    startDate: '',
    endDate: '',
    reservedTokens: '',
  };

  constructor(root: typeof Root) {
    this.root = root;
    makeObservable(this);

    makePersistable(this, {
      name: 'Requests',
      properties: ['data', 'all'],
      storage: window.localStorage,
    });
  }

  @action
  onSelectUser = (user: Requests.All) => {
    this.editing = user;
  };

  @action
  onFetchStatusTokens = async () => {
    const data = await RequestsAPI.onFetchStatusTokens();

    runInAction(() => {
      this.data = data;
    });
  };

  @action
  allUsers = async () => {
    if (this.root.user.data.type !== PROFILE.ADMIN) return;

    const data = await RequestsAPI.allUsers();

    runInAction(() => {
      this.all = isEmpty(data) && !isEmpty(this.all) ? this.all : data;
    });
  };

  @action
  onSetTokens = async ({ amount }: Requests.FormUpdateAmount) => {
    if (this.root.user.data.type !== PROFILE.ADMIN) return;

    this.editing = {
      ...this.editing,
      tokens: {
        ...this.editing.tokens!,
        totalTokens: amount,
      },
    };

    await RequestsAPI.onSetTokens({
      amount,
      userID: this.editing.fbUserID!,
    });

    this.allUsers();

    toast.success('Requisições atualizadas com sucesso!');

    if (this.editing.name === this.root.user.data.name) {
      this.onFetchStatusTokens();
    }

    runInAction(() => {
      this.editing = {};
    });
  };

  @action
  onManagerRequests = async (
    params: Requests.ManagerRequessts,
  ): Promise<void> => {
    const data = await RequestsAPI.onManagerRequests(params);

    this.allUsers();

    runInAction(() => {
      this.data = data;
    });
  };
}
