import React, { FC } from 'react';
import { Formik, REGEX_PWD, observer, useStores, yup } from '~/utils';
import Register from './Register';

const validate = yup.object().shape({
  name: yup
    .string()
    .required('O campo de nome é obrigatório.')
    .test(
      'len',
      'O nome deve ter no mínimo 3 caracteres.',
      (val) => val?.length >= 3,
    )
    .test(
      'len',
      'Insirá seu nome completo.',
      (val = '') => val?.split(' ').length > 1,
    ),
  email: yup.string().email().required('O campo de email é obrigatório.'),
  pwd: yup
    .string()
    .required('A senha é obrigatória.')
    .matches(
      REGEX_PWD,
      'A senha deve conter 8 caracteres,letra maiúscula, minúscula, números e um símbolo especiais.',
    ),
});

const RegisterContainer: FC = () => {
  const { user } = useStores();

  const onSubmit = async ({ email, pwd, name }: User.CreateParams) => {
    await user.create({ name, email, pwd });
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{ email: '', pwd: '', name: '' }}
      validationSchema={validate}
    >
      <Register />
    </Formik>
  );
};

export default observer(RegisterContainer);
