import React from 'react';

import {
  Analysis,
  AnalysisTechnique,
  RequestIcon,
  Solutions,
} from '~/assets/svg';
import { Path } from '~/routes/routes.path';

export const PROFILE = {
  ADMIN: 'ADMIN',
  COMMON: 'COMMON',
};

export const MODAL = {
  LOGIN: 'LOGIN',
  REGISTER: 'REGISTER',
  RECOVERY_PASSWORD: 'RECOVERY_PASSWORD',
  UPDATE_REQUEST: 'UPDATE_REQUEST',
  MANAGER_REQUEST: 'MANAGER_REQUEST',
};

export const REGEX_PWD =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%&*()_-])[A-Za-z\d!@#$%&*()_-]{8,}$/;

export const OPTIONS_MENU = [
  {
    name: 'Soluções Orientada por IA',
    icon: <Solutions />,
    route: Path.Solutions,
  },
  {
    name: 'Análise Funcional de Modelos de IA',
    icon: <Analysis />,
    route: Path.FunctionalModels,
  },
  {
    name: 'Análise Técnica de Modelos de IA',
    icon: <AnalysisTechnique />,
    route: Path.TechniqueModels,
  },
  {
    name: 'Gestão de Requisições',
    icon: <RequestIcon />,
    route: Path.Request,
    restrict: true,
  },
];

export const STATUS_PROCESSING = {
  PENDING: 'Processando',
  REJECTED: 'Rejeitado',
  DONE: 'Finalizado',
};
