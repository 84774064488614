import React, { FC, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  FunctionalModels,
  Home,
  RequestsManager,
  SolutionsIA,
  TechniqueModels,
} from '~/scenes';
import { Firebase } from '~/services';
import Private from './private.route';
import RouterProvider from './provider';
import Public from './public.route';
import { Path } from './routes.path';

const firebase = new Firebase();

const NavigationApp: FC = () => {
  useEffect(() => {
    const unsubscribe = firebase.onAuthStateChanged();
    return () => unsubscribe();
  }, []);

  return (
    <Routes>
      <Route path={Path.HOME} element={<Public render={<Home />} />} />
      <Route
        path={Path.Solutions}
        element={<Private render={<SolutionsIA />} />}
      />
      <Route
        path={Path.FunctionalModels}
        element={<Private render={<FunctionalModels />} />}
      />
      <Route
        path={Path.TechniqueModels}
        element={<Private render={<TechniqueModels />} />}
      />
      <Route
        path={Path.Request}
        element={<Private render={<RequestsManager />} />}
      />
    </Routes>
  );
};

export { RouterProvider };

export default NavigationApp;
